.section-faq {
  height: auto !important;
  color: #111;
  padding: 0 5%;
  background-color: #e9e9e9;

  .section-heading {
    text-align: center;
    font-size: 2.8rem;
    padding-top: 40px;
    margin-top: 0;
  }

  .section-content {
    .faqs {
      width: 85%;
      max-width: 1024px;
      margin: 0 auto;
      padding: 15px;

      .faq {
        margin: 15px;
        padding: 15px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .faq-question {
          position: relative;
          font-size: 1.2rem;
          padding-right: 30%;
          transition: all 0.4s ease;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            transition: all 0.4s ease-out;
            background-image: url("../../assets/illustrations/arrow.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .faq-answer {
          width: 90%;
          opacity: 0;
          max-height: 0;
          overflow-y: hidden;
          transition: all 0.4s ease-out;
          font-size: 1.07rem;
          color: darken(#539dfe, 15%);

          p {
            margin: 0;
          }

          ul {
            margin: 0;
            padding-inline-start: 22px;

            li {
              margin-bottom: 5px;
            }
          }
        }

        &.open {
          .faq-question {
            margin-bottom: 25px;

            &::after {
              transform: translateY(-50%) rotate(180deg);
            }
          }

          .faq-answer {
            max-height: 1000px;
            opacity: 1;
          }
        }
      }
    }

    .image-container {
      margin-top: 60px;
      height: 205px;
      display: flex;
      justify-content: center;

      .illustration {
        width: 380px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .section-content {
      .image-container {
        margin-top: 60px;
        height: 190px;

        .illustration {
          width: 350px;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .section-content {
      .faqs {
        width: 100%;

        .faq {
          .faq-question {
            font-size: 1rem;
            padding-right: 18%;
          }

          .faq-answer {
            font-size: 0.9rem;
          }

          &.open {
            .faq-question {
              margin-bottom: 15px;
            }
          }
        }
      }

      .image-container {
        margin-top: 30px;
        height: 130px;

        .illustration {
          width: 230px;
        }
      }
    }
  }

  &.dark {
    background-color: #292929;
    color: #eee;

    .section-content > .faqs {
      .faq {
        background-color: #000;
        color: #fff;

        p {
          color: #8cbeff;
        }
      }
    }
  }
}
