.App {
  .section {
    height: 760px;
    color: #111;
    padding: 0 5%;

    &:nth-child(even) {
      background-color: #e9e9e9;
    }

    &:nth-child(odd) {
      background-color: #fff;
    }

    &:nth-child(1) {
      background-color: unset;
    }

    .section-heading {
      text-align: center;
      font-size: 2.8rem;
      padding-top: 40px;
      margin-top: 0;
    }

    @media only screen and (max-width: 425px) {
      .section-heading {
        font-size: 2.4rem;
      }
    }

    &.dark {
      color: #eee;

      &:nth-child(odd) {
        background-color: #292929;
      }

      &:nth-child(even) {
        background-color: #323232;
      }

      &:nth-child(1) {
        background-color: unset;
      }
    }
  }
}
