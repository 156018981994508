.sidedrawer {
  &.open {
    transform: translateX(0);
  }

  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  background-color: #e9e9e9;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);

  .options-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .option {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 15px 0;
    }
  }

  &.dark {
    background-color: #111;
    color: #eee;
  }
}
